<template>
  <div style="background-color: #F0F0F2;">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 col-xl-6" style="min-height: calc(100vh - 297px);">
          <div class="mt-4 mb-3" style="background-color: #fff; border: 1px solid #dee2e6; min-height: 60vh;">
            <ul
              class="nav nav-tabs w-100 d-flex justify-content-center nav-pills nav-fill"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active text-black fw-bold fs-8 fs-sm-6"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  style="border-radius: 0%;"
                >
                  會員資料
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-black fw-bold fs-8 fs-sm-6"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#password"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  style="border-radius: 0%;"
                >
                  密碼修改
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link text-black fw-bold fs-8 fs-sm-6"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  style="border-radius: 0%;"
                >
                  收件人管理
                </button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <!-- 會員資料 -->
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <Form v-slot="{ errors }" @submit="onSubmitInfo" class="px-3 px-sm-4 px-lg-5 py-4 py-lg-5">
                  <div class="row mb-3">
                    <label for="inputName" class="col-sm-2 col-form-label pb-1 py-sm-2"
                      >姓名</label
                    >
                    <div class="col-sm-10">
                      <Field
                        type="text"
                        class="form-control"
                        name="姓名"
                        :class="{ 'is-invalid': errors['姓名'] }"
                        :rules="isNameToLong"
                        v-model="memberInfo.fullName"
                        placeholder="請輸入姓名"
                      ></Field>
                      <error-message
                        name="姓名"
                        class="invalid-feedback"
                      ></error-message>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="memberEmail" class="col-sm-2 col-form-label text-nowrap pb-1 py-sm-2"
                      >手機號碼</label
                    >
                    <div v-if="memberInfo.phoneNum" class="col-sm-10 py-sm-2">
                      <p>{{ memberInfo.phoneNum }}</p>
                    </div>
                    <div class="col-sm-10" v-else>
                      <Field
                        type="text"
                        class="form-control"
                        name="手機號碼"
                        :class="{ 'is-invalid': errors['手機號碼'] }"
                        :rules="isPhone"
                        placeholder="請輸入手機號碼"
                        v-model="memberPhone"
                      ></Field>
                      <error-message
                        name="手機號碼"
                        class="invalid-feedback"
                      ></error-message>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="email" class="col-sm-2 col-form-label pb-1 py-sm-2"
                      >Email</label
                    >
                    <div v-if="memberInfo.email" class="col-sm-10 py-sm-2">
                      <p>{{ memberInfo.email }}</p>
                    </div>
                    <div class="col-sm-10" v-else>
                      <Field
                        type="email"
                        class="form-control"
                        name="email"
                        :class="{ 'is-invalid': errors['email'] }"
                        rules="email"
                        placeholder="請輸入 Email"
                        v-model="memberEmail"
                      ></Field>
                      <error-message
                        name="email"
                        class="invalid-feedback"
                      ></error-message>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label class="col-sm-2 col-form-label pb-1 py-sm-2">性別</label>
                    <div class="col-sm-10 py-sm-2">
                      <Field
                        class="form-check-input"
                        name="memberGender"
                        type="radio"
                        id="male"
                        value="1"
                        v-model="memberInfo.userGender"
                      ></Field>
                      <label class="form-check-label me-11 ms-2" for="male"
                        >男</label
                      >
                      <Field
                        class="form-check-input"
                        name="memberGender"
                        id="female"
                        type="radio"
                        value="0"
                        v-model="memberInfo.userGender"
                      ></Field>
                      <label class="form-check-label ms-2" for="female">女</label>
                    </div>
                    <error-message name="memberGender"></error-message>
                  </div>
                  <div class="row mb-3">
                    <label for="memberBirth" class="col-sm-2 col-form-label pb-1 py-sm-2"
                      >生日</label
                    >
                    <p class="col-sm-10 py-sm-2" v-if="memberInfo.userBirthday">
                      {{ fullBirth }}
                    </p>
                    <div v-else class="col-sm-10">
                      <div class="row g-2">
                        <div class="col">
                          <select
                            id="birthYear"
                            class="form-select"
                            v-model.number="memberBirth.year"
                            @change="getDay"
                          >
                            <option value="" selected>西元年</option>
                            <option :value="year" v-for="year in dateList.yearList" :key="year">
                              {{ year }}
                            </option>
                          </select>
                        </div>
                        <div class="col">
                          <select
                            id="birthMonth"
                            class="form-select"
                            v-model="memberBirth.month"
                            @change="getDay"
                          >
                            <option value="" selected>月份</option>
                            <option
                              :value="month"
                              v-for="month in dateList.monthList"
                              :key="month"
                            >
                              {{ month }}
                            </option>
                          </select>
                        </div>
                        <div class="col">
                          <select
                            id="birthDate"
                            class="form-select"
                            v-model="memberBirth.day"
                          >
                            <option value="" selected>日</option>
                            <option :value="day" v-for="day in dateList.dayList" :key="day">
                              {{ day }}
                            </option>
                          </select>
                        </div>
                    </div>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <label for="memberAddress" class="col-sm-2 col-form-label text-nowrap"
                      >寄送地址</label
                    >
                    <div class="col-sm-10">
                      <div class="row g-2">
                        <div class="col-6 col-md-3">
                          <select
                            id="country"
                            class="form-select pe-2"
                            v-model="memberInfo.city"
                            @change="
                              getTown(memberInfo.city);
                              memberInfo.district = ''
                            "
                          >
                            <option value="" selected>縣市</option>
                            <option
                              :value="country.Country"
                              v-for="country in addressList.countryList"
                              :key="country"
                              >{{ country.CountryName }}</option
                            >
                          </select>
                        </div>
                        <div class="col-6 col-md-3">
                          <select
                            id="town"
                            class="form-select pe-2"
                            v-model="memberInfo.district"
                          >
                            <option value="" selected>區名</option>
                            <option
                              v-for="town in addressList.townList"
                              :key="town"
                              :value="town.District"
                              >{{ town.DistrictName }}</option
                            >
                          </select>
                        </div>
                        <div class="col col-md">
                          <input
                            type="text"
                            class="form-control"
                            v-model="memberInfo.otherAddress"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-check">
                      <input
                        class="form-check-input"
                        name="subscribe"
                        id="subscribe"
                        type="checkbox"
                        v-model="memberInfo.isSubscribe"
                      />
                      <label class="form-check-label ms-2" for="subscribe">是否訂閱電子報</label>
                      </div>
                      <div class="mb-3">
                      </div>
                    </div>
                  </div>
                  <div class="mb-1 mt-2">發票兌獎會員編號： PAPAF{{ memberInfo.memberId }}</div>
                  <div class="mb-3 mt-2">歸戶認証碼：{{ memberInfo.reclaimCode }}</div>
                  <div class="d-grid gap-2 mt-2">
                    <button
                      class="btn btn-primary text-white py-2"
                      type="submit"
                    >
                      確認變更
                    </button>
                  </div>
                </Form>
              </div>
              <!-- 密碼修改 -->
              <div
                class="tab-pane fade"
                id="password"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <Form v-slot="{ errors }" @submit="submitPasswordInfo" class="px-3 px-sm-4 px-lg-5 py-4 py-lg-5">
                  <h5 class="fs-5 fw-bold mt-2 border-secondary pb-3" id="exampleModalLabel">個人密碼修改</h5>
                  <div class="row mb-3 mt-4">
                    <label for="oldPassword" class="col-sm-2 col-form-label">原密碼</label>
                    <div class="position-relative col-sm-10">
                      <Field
                        type="password"
                        class="form-control"
                        id="oldPassword"
                        name="原密碼"
                        :class="{ 'is-invalid': errors['原密碼'] }"
                        :rules="checkPwd"
                        placeholder="請輸入密碼"
                        v-model="oldPassword"
                      ></Field>
                      <a
                        href="#"
                        @click.prevent="
                          isOldShow = !isOldShow;
                          changePwdStatus(isOldShow, $event)
                        "
                        class="passwordImage position-absolute d-block"
                      >
                        <img
                          v-if="isOldShow"
                          src="../../assets/img/Icons-34.svg"
                        />
                        <img v-else src="../../assets/img/Icons-33.svg" />
                      </a>
                      <error-message
                        name="原密碼"
                        class="invalid-feedback"
                      ></error-message>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="newPassword" class="col-sm-2 col-form-label">新密碼</label>
                    <div class="position-relative col-sm-10">
                      <Field
                        type="password"
                        class="form-control"
                        id="newPassword"
                        name="新密碼"
                        :class="{ 'is-invalid': errors['新密碼'] }"
                        :rules="confirmOldPwd"
                        placeholder="請輸入至少6碼且英數混合"
                        v-model="newPassword"
                      ></Field>
                      <a
                        href="#"
                        @click.prevent="
                          isNewShow = !isNewShow;
                          changePwdStatus(isNewShow, $event)
                        "
                        class="passwordImage position-absolute d-block"
                      >
                        <img
                          v-if="isNewShow"
                          src="../../assets/img/Icons-34.svg"
                        />
                        <img v-else src="../../assets/img/Icons-33.svg" />
                      </a>
                      <error-message
                        name="新密碼"
                        class="invalid-feedback"
                      ></error-message>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="confirmPassword" class="col-sm-2 col-form-label text-nowrap">確認密碼</label>
                    <div class="position-relative col-sm-10">
                      <Field
                        type="password"
                        class="form-control"
                        id="confirmPassword"
                        name="確認密碼"
                        :class="{ 'is-invalid': errors['確認密碼'] }"
                        :rules="confirmPwd"
                        placeholder="請再次輸入新密碼"
                        v-model="confirmPassword"
                      ></Field>
                      <a
                        href="#"
                        @click.prevent="
                          isConfirmShow = !isConfirmShow;
                          changePwdStatus(isConfirmShow, $event)
                        "
                        class="passwordImage position-absolute d-block"
                      >
                        <img
                          v-if="isConfirmShow"
                          src="../../assets/img/Icons-34.svg"
                        />
                        <img v-else src="../../assets/img/Icons-33.svg" />
                      </a>
                      <error-message
                        name="確認密碼"
                        class="invalid-feedback"
                      ></error-message>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="input-group col-sm-10">
                      <Field
                        type="text"
                        class="form-control"
                        id="confirmCode"
                        name="驗證碼"
                        :class="{ 'is-invalid': errors['驗證碼'] }"
                        rules="required"
                        placeholder="請輸入驗證碼"
                        v-model="captcha"
                      ></Field>
                      <button
                        class="verifyBtn btn border"
                        type="button"
                        @click.prevent="getVerifyCode"
                      >
                        <img
                          class="verifyImg pe-2"
                          alt="verifyCode image"
                        />
                        <img
                          class="refreshImg"
                          src="@/assets/img/Icons-35.svg"
                        />
                      </button>
                      <error-message
                        name="驗證碼"
                        class="invalid-feedback"
                      ></error-message>
                    </div>
                    <div class="d-flex justify-content-end text-muted fs-9">
                      驗證碼不須區分大小寫
                    </div>
                  </div>
                  <div class="d-grid gap-2 mt-4">
                    <button
                      class="btn btn-primary text-white py-2"
                      type="submit"
                    >
                      確認變更
                    </button>
                  </div>
                </Form>
              </div>
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <Form class="mt-5 px-4 px-sm-4 px-lg-7 mb-4 mb-lg-5">
                  <h1 class="fs-5 fw-bold mt-2 border-secondary pb-3">收件人管理</h1>
                  <div class="bg-white pt-6" v-if="consignees.length === 0">
                    <p class="fs-3 text-center fw-bold">目前無常用收件人</p>
                  </div>
                  <div v-else class="border-top border-1 px-0 px-md-3 py-3" v-for="consignee in consignees" :key="consignee.id">
                    <div class="row">
                      <div class="col-md-7 col-lg-6">
                        <p class="text-secondary fs-6"><i class="bi bi-person-fill me-2"></i>姓名:<span class="me-2"></span>{{ consignee.consigneeName }}</p>
                        <p class="text-secondary fs-6"><i class="bi bi-telephone-fill me-2"></i>電話:<span class="me-2"></span>{{ consignee.cellphone }}</p>
                        <p class="text-secondary fs-6"><i class="bi bi-envelope-fill me-2"></i>電子郵件:{{ consignee.consigneeEmail }}</p>
                        <p class="text-secondary fs-6"><i class="bi bi-geo-alt-fill me-2"></i>地址:<span class="me-2"></span>{{ consignee.cityName }}{{ consignee.districtName }}{{ consignee.otherAddress }}</p>
                      </div>
                      <div class="col-md-5 col-lg-6 mt-2 mt-md-0">
                        <div class="updateBtn d-flex justify-content-end align-items-end h-100">
                          <button type="button" class="btn btn-outline-primary shadow-none me-2 px-3 px-lg-4" @click="removeConsignee(consignee.consigeeId)">移除</button>
                          <button type="button" class="btn btn-primary text-white shadow-none me-2 px-3 px-lg-4" @click="editConsignee(consignee)">編輯</button>
                        </div>
                      </div>
                    </div>
                  </div>
                <!-- 編輯收件人資訊 -->
                  <div class="modal fade" ref="editModal" id="editModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">編輯收件人資訊</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                          <Form v-slot="{ errors }" @submit="submitConsignee">
                            <div class="mb-3">
                              <label for="consigneeName">姓名</label>
                              <Field
                                type="text"
                                class="form-control"
                                name="姓名"
                                :class="{ 'is-invalid': errors['姓名'] }"
                                placeholder="請輸入姓名"
                                v-model.trim="consigneeInfo.consigneeName"
                              ></Field>
                              <error-message
                                name="姓名"
                                class="invalid-feedback"
                              ></error-message>
                            </div>
                            <div class="mb-3">
                              <label for="memberEmail" class="form-label">手機號碼</label>
                              <Field
                                name="手機"
                                type="tel"
                                class="form-control"
                                placeholder="請輸入手機號碼"
                                autoCompleteType="disabled"
                                :rules="isPhone"
                                :class="{ 'is-invalid': errors['手機'] }"
                                v-model.trim="consigneeInfo.cellphone"
                                autocomplete="off"
                              ></Field>
                              <error-message
                                name="手機"
                                class="invalid-feedback"
                              ></error-message>
                            </div>
                            <div class="mb-3">
                              <label for="email">Email</label>
                              <Field
                                type="email"
                                class="form-control"
                                name="email"
                                :class="{ 'is-invalid': errors['email'] }"
                                rules="email"
                                placeholder="請輸入 Email"
                                v-model.trim="consigneeInfo.consigneeEmail"
                              ></Field>
                              <error-message
                                name="email"
                                class="invalid-feedback"
                              ></error-message>
                            </div>
                            <div class="mb-3">
                              <label for="memberAddress" class="form-label">常用寄送地址</label>
                              <div class="row g-2">
                                <div class="col-3">
                                  <select
                                    id="country"
                                    class="form-select pe-2"
                                    v-model="consigneeInfo.city"
                                    @change="getTown(consigneeInfo.city); consigneeInfo.district = '';"
                                  >
                                    <option value="" selected>縣市</option>
                                    <option
                                      :value="country.Country"
                                      v-for="country in addressList.countryList"
                                      :key="country"
                                      >{{ country.CountryName }}</option
                                    >
                                  </select>
                                </div>
                                <div class="col-3">
                                  <select
                                    id="town"
                                    class="form-select pe-2"
                                    v-model="consigneeInfo.district"
                                  >
                                    <option value="" selected>區名</option>
                                    <option
                                      v-for="town in addressList.townList"
                                      :key="town"
                                      :value="town.District"
                                      >{{ town.DistrictName }}</option
                                    >
                                  </select>
                                </div>
                                <div class="col">
                                  <Field
                                    type="text"
                                    class="form-control"
                                    name="地址"
                                    :class="{ 'is-invalid': errors['地址'] }"
                                    v-model.trim="consigneeInfo.otherAddress"
                                  ></Field>
                                  <error-message
                                    name="地址"
                                    class="invalid-feedback"
                                  ></error-message>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex justify-content-end mt-5">
                              <button type="button" class="btn btn-secondary px-3 me-2" data-bs-dismiss="modal">取消</button>
                              <button type="submit" class="btn btn-primary text-white px-3">編輯</button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countryName from '@/assets/country.json'
import Modal from 'bootstrap/js/dist/modal'
import ValidateSvc from '@/service/validate-service.js'

export default {
  data () {
    return {
      // api資料回傳區
      memberInfo: {}, // ?查詢會員資料 API
      countryName, // ?縣市鄉鎮代碼 json
      addressList: {
        // ?地址選單
        countryList: [],
        townList: []
      },
      dateList: {
        // ?日期選單
        yearList: [],
        monthList: [],
        dayList: []
      },
      memberBirth: {
        // ?使用者輸入的出生年月日
        year: '',
        month: '',
        day: ''
      },
      memberPhone: '', // ?使用者輸入的手機
      memberEmail: '', // ?使用者輸入的 Email
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      captcha: '',
      isOldShow: true, // ?原密碼明碼顯示切換,
      isNewShow: true, // ?新密碼明碼顯示切換,
      isConfirmShow: true, // ?確認密碼明碼顯示切換,
      validateCode: '', // ?驗證碼 API 資料,
      news: false,
      addUsedContactList: { // 以js操控常用聯絡人Modal接值
        consigneeName: '',
        consigneeCellphone: '',
        consigneeCity: '',
        consigneeDistrict: '',
        consigneeOtherAddress: '',
        consigneeEmail: ''
      },
      checkOrder: { //  訂單送出時要post的物件
        items:
        [
        ],
        payInfo:
        {
        },
        buyer:
        {
          buyerName: '',
          buyerCellphone: '',
          buyerCity: '',
          buyerDistrict: '',
          buyerOtherAddress: '',
          buyerEmail: ''
        },
        consignee:
        {
          consigneeName: '',
          consigneeCellphone: '',
          consigneeCity: '',
          consigneeDistrict: '',
          consigneeOtherAddress: '',
          consigneeEmail: ''
        },
        Invoice:
        {
          invoiceType: '',
          invoiceMark: '',
          carrierType: '',
          carrierCode: '',
          companyName: '',
          BizAdmNo: ''
        },
        Memo: '',
        consignees: []
      },
      consignees: [],
      consigneeInfo: {},
      editModal: ''
      // hasConsignee: false
    }
  },
  methods: {
    getMemberInfo () {
      // ?取得會員資料
      const url = `${process.env.VUE_APP_API}/api/members/SelectMemberInfo`
      this.axios.get(url).then(res => {
        if (res.data.rtnCode === 0) {
          this.memberInfo = res.data.info
          if (this.memberInfo.userGender !== 2) {
            this.memberInfo.userGender = this.memberInfo.userGender.toString()
          }
          if (this.memberInfo.phoneNum) {
            this.memberPhone = this.memberInfo.phoneNum
          }
          if (this.memberInfo.email) {
            this.memberEmail = this.memberInfo.email
          }
          // ?產生生日年月日
          this.getYearMonth()
          // ?地址縣市鄉鎮選單
          this.getCountry()
          this.getTown(this.memberInfo.city)
        } else {
          this.$swal.fire({
            title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
        }
      })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getMemberInfo()
          }
        })
    },
    getCountry () {
      // ? 產生縣市清單
      const list = new Set()
      this.countryName.forEach(item => {
        list.add(item.CountryName)
      })
      const listAry = [...list]
      const list2 = []
      listAry.forEach(item => {
        const num = this.countryName.findIndex(
          item2 => item2.CountryName === item
        )
        list2.push({
          Country: this.countryName[num].Country,
          CountryName: item
        })
      })
      this.addressList.countryList = list2
    },
    // getTown(city) => city有memberInfo&consigneeInfo
    getTown (city) {
      // ? 產生鄉鎮清單
      this.addressList.townList = this.countryName.filter(
        item => item.Country === city
      )
    },
    getYearMonth () {
      // ? 產生生日"年"、"月"選單
      const year = new Date()
      for (let i = 1930; i < year.getFullYear() + 1; i++) {
        this.dateList.yearList.push(i)
      }
      for (let i = 1; i < 13; i++) {
        if (i < 10) {
          this.dateList.monthList.push('0' + i)
        } else {
          this.dateList.monthList.push(i)
        }
      }
    },
    getDay () {
      // ? 產生生日"日"選單
      this.dateList.dayList = []
      // ?清空西元年，月日一併清空
      if (!this.memberBirth.year) {
        this.memberBirth.month = ''
        this.memberBirth.day = ''
        return
      }
      // ?清空月，日一併清空
      if (!this.memberBirth.month) {
        this.memberBirth.day = ''
        return
      }
      const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
      if (this.memberBirth.year % 4 === 0) {
        days[1] = 29
      }
      for (let i = 1; i < days[parseInt(this.memberBirth.month) - 1] + 1; i++) {
        if (i < 10) {
          this.dateList.dayList.push('0' + i)
        } else {
          this.dateList.dayList.push(i)
        }
      }
      // ?月對應的天數如比前一個天數少，清空日
      if (this.dateList.dayList.length < this.memberBirth.day) {
        this.memberBirth.day = ''
      }
    },
    isPhone (phone) {
      // ? 驗證手機格式
      return ValidateSvc.CheckPhoneNum(phone)
    },
    isNameToLong (value) {
      // ? 驗證姓名長度不超過 5
      if (value) {
        const nameString = /^.{0,5}$/
        return nameString.test(value) ? true : '長度請勿超過 5 個字'
      } else {
        return true
      }
    },
    onSubmitInfo () {
      // ?變更會員資料 API 傳送
      // ?判斷地址填寫完整(且三個可留空)
      if (
        (!this.memberInfo.city &&
          !this.memberInfo.district &&
          !this.memberInfo.otherAddress) ||
        (this.memberInfo.city &&
          this.memberInfo.district &&
          this.memberInfo.otherAddress)
      ) {
      } else {
        this.$swal.fire({
          title: '請填寫完整地址',
          confirmButtonColor: '#003894',
          customClass: {
            title: 'text-class'
          }
        })
        return
      }
      // ?第一次填生日 + 判斷生日填寫完整(且三個可留空)
      if (!this.memberInfo.userBirthday) {
        if ((this.memberBirth.year &&
            this.memberBirth.month &&
            this.memberBirth.day)
        ) {
          this.memberInfo.userBirthday = `${this.memberBirth.year}${this.memberBirth.month}${this.memberBirth.day}`
        } else if (!this.memberBirth.year &&
            !this.memberBirth.month &&
            !this.memberBirth.day) {
          this.memberInfo.userBirthday = ''
        } else {
          this.$swal.fire({
            title: '請填寫完整生日',
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
          return
        }
      }
      const updateInfo = {
        userGender: parseInt(this.memberInfo.userGender),
        userBirthday: this.memberInfo.userBirthday,
        email: this.memberEmail,
        phoneNum: this.memberPhone,
        fullName: this.memberInfo.fullName,
        city: this.memberInfo.city,
        district: this.memberInfo.district,
        otherAddress: this.memberInfo.otherAddress,
        isSubscribe: this.memberInfo.isSubscribe
      }
      const url = `${process.env.VUE_APP_API}/api/members/EditMemberInfo`
      this.$http.post(url, updateInfo).then(res => {
        if (res.data.rtnCode === 0) {
          this.$swal.fire({
            title: '修改成功',
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
        } else {
          this.$swal.fire({
            title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
        }
      })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getOrder()
          }
        })
    },
    /* 密碼 * 切換 */
    changePwdStatus (status, $event) {
      if (status) {
        $event.currentTarget.previousSibling.setAttribute('type', 'password')
      } else {
        $event.currentTarget.previousSibling.setAttribute('type', 'text')
      }
    },
    // ? 第一格 驗證密碼是否符合格式
    checkPwd (password) {
      return ValidateSvc.CheckPwd(password)
    },
    // ? 第二格新密碼
    confirmOldPwd (confirmoldpassword) {
      return ValidateSvc.CheckNewPwd(confirmoldpassword, this.oldPassword)
    },
    // ? 第三格確認密碼
    confirmPwd (confirmpassword) {
      return ValidateSvc.ConfirmFinalPwd(confirmpassword, this.newPassword)
    },
    // ? 修改密碼送出 API
    submitPasswordInfo (values, { resetForm }) {
      const updatePwd = {
        oldSecretCode: this.oldPassword,
        newSecretCode: this.newPassword,
        captcha: this.captcha,
        validTransactionId: this.validateCode
      }
      const url = `${process.env.VUE_APP_API}/api/members/ChangeSecretCode`
      this.$http.post(url, updatePwd).then(res => {
        if (res.data.rtnCode === 0) {
          this.$swal.fire({
            title: '修改成功，下次請使用新密碼登入',
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
          resetForm()
          this.getVerifyCode()
        } else if (res.data.rtnCode === 1002) {
          // ?輸入的原密碼與目前密碼不一致
          this.$swal.fire({
            title: '原密碼輸入錯誤',
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
          this.getVerifyCode()
        } else {
          this.$swal.fire({
            title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
            confirmButtonColor: '#003894',
            customClass: {
              title: 'text-class'
            }
          })
          this.getVerifyCode()
        }
      })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getOrder()
          }
        })
    },
    // ? 圖形驗證碼
    getVerifyCode () {
      ValidateSvc.GetValidateCode().then((SvcData) => {
        this.validateCode = SvcData.transactionId
        document.querySelector('.verifyImg').src = SvcData.validateImg
      })
        .catch((err) => {
          if (err.response.status === 401) {
            this.getOrder()
          }
        })
    },
    addUsedContact () { // ? 加入常用聯絡人
      this.addUsedContactList.consigneeName = this.checkOrder.consignee.consigneeName
      this.addUsedContactList.consigneeCity = this.checkOrder.consignee.consigneeCity
      this.addUsedContactList.consigneeDistrict = this.checkOrder.consignee.consigneeDistrict
      this.addUsedContactList.consigneeOtherAddress = this.checkOrder.consignee.consigneeOtherAddress
      this.addUsedContactList.consigneeCellphone = this.checkOrder.consignee.consigneeCellphone
      this.addUsedContactList.consigneeEmail = this.checkOrder.consignee.consigneeEmail
      const url = `${process.env.VUE_APP_API}/api/consignee/add`
      this.axios.post(url, this.addUsedContactList).then(res => {
        this.$swal.fire({
          position: 'center',
          title: '該聯絡人已加入常用清單',
          showConfirmButton: false,
          timer: 2500,
          width: 400,
          customClass: {
            title: 'text-class',
            confirmButton: 'confirm-btn-class',
            cancelButton: 'confirm-btn-class'
          }
        })
      })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getOrder()
          }
        })
    },
    editConsignee (item) {
      this.consigneeInfo = { ...item }
      this.getTown(this.consigneeInfo.city)
      this.editModal.show()
    },
    removeConsignee (id) {
      this.$swal.fire({
        title: '確定要刪除嗎?',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#003894',
        cancelButtonColor: '#4D4D4D',
        confirmButtonText: '是',
        cancelButtonText: '否',
        width: 400,
        customClass: {
          title: 'text-class',
          confirmButton: 'confirm-btn-class',
          cancelButton: 'confirm-btn-class'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const url = `${process.env.VUE_APP_API}/api/consignee/remove`
          this.$http.get(url, { params: { consigneeId: id } })
            .then((res) => {
              if (res.data.rtnCode === 0) {
                this.$swal.fire({
                  title: '已刪除',
                  confirmButtonColor: '#003894',
                  customClass: {
                    title: 'text-class'
                  }
                })
                this.getConsigees()
              } else {
                this.$swal.fire({
                  title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
                  confirmButtonColor: '#003894',
                  customClass: {
                    title: 'text-class'
                  }
                })
              }
            })
        }
      })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getOrder()
          }
        })
    },
    getConsigees () {
      const url = `${process.env.VUE_APP_API}/api/Consignee/List`
      this.$http.get(url)
        .then((res) => {
          if (res.data.rtnCode === 0) {
            this.consignees = res.data.info
            this.getAddress()
            this.getCountry()
          } else {
            this.$swal.fire({
              title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
              confirmButtonColor: '#003894',
              customClass: {
                title: 'text-class'
              }
            })
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getOrder()
          }
        })
    },
    getAddress () {
      this.consignees.forEach(item => {
        if (item.city || item.district) {
          item.cityName = this.countryName[this.countryName.findIndex(item1 => item1.Country === item.city)].CountryName
          item.districtName = this.countryName[this.countryName.findIndex(item1 => item1.District === item.district)].DistrictName
        }
      })
    },
    submitConsignee () {
      // 檢查是否填寫完整地址
      if ((!this.consigneeInfo.city && !this.consigneeInfo.district && !this.consigneeInfo.otherAddress) || (this.consigneeInfo.city && this.consigneeInfo.district && this.consigneeInfo.otherAddress)) {
      } else {
        this.$swal.fire({
          title: '請填寫完整地址',
          confirmButtonColor: '#003894',
          customClass: {
            title: 'text-class'
          }
        })
        return
      }
      // 修改常用收件人 API
      const obj = {
        consigneeId: this.consigneeInfo.consigeeId,
        consigneeName: this.consigneeInfo.consigneeName,
        consigneeCellphone: this.consigneeInfo.cellphone,
        consigneeCity: this.consigneeInfo.city,
        consigneeDistrict: this.consigneeInfo.district,
        consigneeOtherAddress: this.consigneeInfo.otherAddress,
        consigneeEmail: this.consigneeInfo.consigneeEmail
      }

      const url = `${process.env.VUE_APP_API}/api/consignee/update`
      this.$http.post(url, obj)
        .then((res) => {
          if (res.data.rtnCode === 0) {
            this.$swal.fire({
              title: '已編輯',
              confirmButtonColor: '#003894',
              customClass: {
                title: 'text-class'
              }
            })
            this.editModal.hide()
            this.getConsigees()
          } else {
            this.$swal.fire({
              title: `${res.data.rtnMsg}(${res.data.rtnCode})`,
              confirmButtonColor: '#003894',
              customClass: {
                title: 'text-class'
              }
            })
            this.editModal.hide()
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // 重打 API
            this.getOrder()
          }
        })
    }
  },
  computed: {
    fullBirth () {
      if (this.memberInfo.userBirthday) {
        const birthAry = this.memberInfo.userBirthday.split('')
        const year = birthAry.slice(0, 4).join('')
        const month = birthAry.slice(4, 6).join('')
        const date = birthAry.slice(6).join('')
        return `${year} 年 ${month} 月 ${date} 日`
      }
      return ''
    }
  },
  mounted () {
    this.getMemberInfo()
    this.getVerifyCode()
    this.getConsigees()
    this.editModal = new Modal(this.$refs.editModal)
  }
}
</script>

<style lang="scss" scoped>
.verifyBtn {
  .refreshImg {
    width: 20px;
    height: 20px;
  }
}
.passwordImage {
  top: 5px;
  right: 20px;
  img {
    width: 30px;
  }
}
.form-control.is-invalid{
  background-image: none;
}

.verifyImg{
  width: 100px;
  height: 25px;
}

.passwordImage{
  img{
    width: 24px;
  }
}

.updateBtn{
  .btn-outline-primary:hover{
    color: #fff !important;
  }
}

.nav-pills .nav-link {
  background-color: #dee2e6;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  // background-color: rgb(240, 240, 242);
  background-color: #fff;
}

// .nav-fill .nav-item .nav-link {
//   background-color: #dee2e6;
// }

</style>
